import { CSSProperties, useEffect } from 'react';

import { Box } from 'components/Box';
import { closeDropdownFromElementWithin } from 'components/Dropdown/closeDropdownFromElementWithin';
import { Icon } from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconName';
import { trackClicked } from 'utils/analytics/track/trackClicked';
import { getStaticImageSrc } from 'utils/getImageSrc';

import {
  DropdownFoldBottomMenuPanel,
  DropdownFoldMenuButtonLink,
  DropdownFoldMenuHolder,
  DropdownFoldMenuPanel,
  DropdownFoldMenuPanelButtonLinksHolder,
  DropdownFoldMenuPanelTitle,
  DropdownFoldMenuRichContentHolder,
  DropdownFoldMenuRichLink,
  DropdownFoldMenuRichLinkDescription,
  DropdownFoldMenuRichLinkTitle,
  DropdownFoldMenuRichLinksHolder,
  DropdownMobileBackdrop,
  dropdownFoldMenuColumnsCountVar,
} from './DropdownFoldMenu.styled';

type Props = {
  left: {
    title: string;
    items: Array<{
      iconName: IconName;
      title: string;
      href: string;
      qaId: string;
      trackClicked: { name: string; context: string };
    }>;
  };
  right:
    | {
        title: string;
        items: Array<{
          image: string;
          imageHover: string;
          title: string;
          description: string;
          href: string;
          qaId: string;
          trackClicked: { name: string; context: string };
        }>;
      }
    | undefined;
  bottom?: React.ReactNode;
};

export function DropdownFoldMenu({ left, right, bottom }: Props) {
  // Pre-load hover images to avoid loading flicker
  useEffect(() => {
    right?.items.forEach((item) => {
      const img = new Image();
      img.src = getStaticImageSrc(item.imageHover);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DropdownFoldMenuHolder>
      <Box display={['block', null, null, 'flex']} flexDirection="row">
        <DropdownFoldMenuPanel>
          <DropdownFoldMenuPanelTitle>{left.title}</DropdownFoldMenuPanelTitle>

          <DropdownFoldMenuPanelButtonLinksHolder
            style={
              {
                [dropdownFoldMenuColumnsCountVar]: Math.ceil(
                  left.items.length / 3,
                ),
              } as CSSProperties
            }
          >
            {left.items.map((item) => (
              <DropdownFoldMenuButtonLink
                key={item.title}
                to={item.href}
                data-qa-id={item.qaId}
                onClick={(event) => {
                  trackClicked(item.trackClicked.name, {
                    context: item.trackClicked.context,
                  });
                  closeDropdownFromElementWithin(event.target as HTMLElement);
                }}
              >
                <Icon name={item.iconName} size={16} />
                <span>{item.title}</span>
              </DropdownFoldMenuButtonLink>
            ))}
          </DropdownFoldMenuPanelButtonLinksHolder>
        </DropdownFoldMenuPanel>

        {right && (
          <DropdownFoldMenuPanel>
            <DropdownFoldMenuPanelTitle>
              {right.title}
            </DropdownFoldMenuPanelTitle>

            <DropdownFoldMenuRichLinksHolder>
              {right.items.map((item) => (
                <DropdownFoldMenuRichLink
                  key={item.title}
                  to={item.href}
                  data-qa-id={item.qaId}
                  onClick={(event) => {
                    trackClicked(item.trackClicked.name, {
                      context: item.trackClicked.context,
                    });

                    closeDropdownFromElementWithin(event.target as HTMLElement);
                  }}
                  $background={`url(${getStaticImageSrc(item.image)})`}
                  $backgroundHover={`url(${getStaticImageSrc(
                    item.imageHover,
                  )})`}
                >
                  <DropdownFoldMenuRichContentHolder>
                    <DropdownFoldMenuRichLinkTitle>
                      {item.title}
                    </DropdownFoldMenuRichLinkTitle>

                    <DropdownFoldMenuRichLinkDescription>
                      {item.description}
                    </DropdownFoldMenuRichLinkDescription>
                  </DropdownFoldMenuRichContentHolder>
                </DropdownFoldMenuRichLink>
              ))}
            </DropdownFoldMenuRichLinksHolder>
          </DropdownFoldMenuPanel>
        )}
      </Box>

      {bottom && (
        <DropdownFoldBottomMenuPanel>{bottom}</DropdownFoldBottomMenuPanel>
      )}

      <DropdownMobileBackdrop
        onClick={(event) =>
          closeDropdownFromElementWithin(event.target as HTMLElement)
        }
      />
    </DropdownFoldMenuHolder>
  );
}
